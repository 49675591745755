import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import {
  isHydrated,
  getPersistedStore,
  clearPersistedStore,
  makePersistable,
  stopPersisting,
  pausePersisting,
  startPersisting,
  isPersisting,
} from 'mobx-persist-store';
import queryString from 'query-string';
import _ from 'lodash';
import { createBrowserHistory } from 'history';
import { getCookie } from '../Utils/getSetCookie/getsetCookie';
import { computedFilters } from '../Utils/computedFilters';
import { computedFiltersDescription } from '../Utils/computedFiltersDescription';

export default abstract class PageStore {
  count = 0;
  page = 1;
  perPage = 30;
  noFetch = false;
  portal = null;
  userId = '';
  isLoading = true;
  isLoadingCount = true;
  errorTimeout = null;
  errorOther: any = null;
  items: any[] = [];
  filters: any = null;
  defaultFilters: any = {};
  inputFilterValue = '';
  tableHeader: {
    name: string;
    title?: string;
    sortable?: boolean;
    value?: any;
    customSort?: (a: any, b: any) => number;
  }[] = [];
  order: string | null = null;
  direction = 'asc';
  history = createBrowserHistory();
  allowHandlingParamsChange = true;
  allowUrlChange = true;
  abstract endpoint: string;
  disablePersist: boolean = false;

  constructor(userId: string) {
    makeObservable(this, {
      count: observable,
      portal: observable,
      page: observable,
      perPage: observable,
      inputFilterValue: observable,
      items: observable,
      filters: observable,
      order: observable,
      direction: observable,
      tableHeader: observable,
      isLoading: observable,
      isLoadingCount: observable,
      defaultFilters: observable,
      userId: observable,
      noFetch: observable,
      setInputFilterValue: action,
      handleSetNoLimit: action,
      setFilters: action,
      setOrder: action,
      setUserId: action,
      handleFetch: action,
      setIsLoading: action,
      setIsLoadingCount: action,
      setItems: action,
      setCount: action,
      initPersist: action,
      setPortal: action,
      clearStoredDate: action,
      setFiltersWithHydrate: action,
      setBrandsFilter: action,
      setNoFetch: action,
      deleteFilter: action,
      revertFilter: action,
      counters: computed,
      filtersAsArray: computed,
      computedFilters: computed,
      filteredItems: computed,
      isHydrated: computed,
      persistStore: computed,
      exportLink: computed,
      storeName: computed,
      errorTimeout: observable,
      setErrorTimeout: action,
      errorOther: observable,
      setErrorOther: action,
      computedFiltersDescription: computed,
    });

    if (userId) {
      this.userId = userId;
    }
  }

  get storeName() {
    return window.location.pathname + '_' + this.userId;
  }

  handleFetch() { }
  handleCount() { }
  filterSearch(item: any) { }
  get counters(): any[] {
    return [];
  }

  setErrorTimeout(errorTimeout: any) {
    this.errorTimeout = errorTimeout;
  }

  setErrorOther(errorOther: any) {
    this.errorOther = errorOther;
  }

  searchToFilters(search: any) {
    runInAction(() => {
      try {
        const extractedParams: {
          page?: number;
          perPage?: number;
          order?: string;
          direction?: string;
        } = _.chain(search).mapValues((v) => {
          if (v === undefined) {
            return undefined;
          }
          return JSON.parse(v);
        }).value();

        this.page = extractedParams.page || this.page;
        this.perPage = extractedParams.perPage || this.perPage;

        this.order = extractedParams.order || this.order;
        this.direction = extractedParams.direction || this.direction;

        const extractedFilters = _.omit(extractedParams, [
          'perPage',
          'page',
          'order',
          'direction',
        ]);

        if (Object.keys(extractedFilters).length > 0) {
          this.filters = extractedFilters;
        } else {
          this.filters = null;
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  async setFilters(filtersState: any, disablePersist?: any) {
    //Sauvegarder les filtres
    if (!disablePersist && !isPersisting) {
      startPersisting(this);
    }
    if (this.errorTimeout !== null) {
      this.setErrorTimeout(null);
    }
    if (this.errorOther !== null) {
      this.setErrorOther(null);
    }
    this.filters = filtersState;
    this.handleCount();
    this.handleFetch();
    this.allowHandlingParamsChange = true;
  }

  highlight(value: any) {
    if (value) {
      if (this.inputFilterValue === '') {
        return computed(() => false).get();
      } else {
        return computed(() =>
          value.toLowerCase().includes(this.inputFilterValue.toLowerCase())
        ).get();
      }
    }
  }

  async firstFetch() {
    if (!this.disablePersist) await this.initPersist();
    this.handleFetch();
    this.handleCount();
  }

  get filtersAsArray() {
    return _.chain(this.filters)
      .pickBy()
      .keys()
      .value()
      .map((o) => this.filters[o].label);
  }

  async setFiltersWithHydrate(filtersState: any) {
    if (!this.isHydrated) {
      await this.initPersist();
    }
    this.setFilters(filtersState);
  }

  setInputFilterValue(value: any) {
    this.inputFilterValue = value;
  }

  get filteredItems() {
    return this.items.filter((item) => this.filterSearch(item));
  }

  get exportLink() {
    const queryString = new URLSearchParams({
      filter: JSON.stringify({
        where: this.computedFilters,
      }),
      csv: 'true',
      access_token: getCookie('access_token') as string,
    });
    const link =
      process.env.REACT_APP_MONIBRAND_BACKEND_API_URL +
      this.endpoint +
      '?' +
      queryString.toString();
    return link;
  }

  /**
   *
   * @param {type: 'page|perPage',value: number} param type of call and value
   */
  setPage({ type, value }: { type: 'page' | 'perPage'; value: number }) {
    const getParams = queryString.parse(this.history.location.search);

    if (type === 'page') {
      this.page = value;
    } else if (type === 'perPage') {
      this.perPage = value;
      this.page = 1;
    }
    getParams.page = this.page as unknown as string;
    getParams.perPage = this.perPage as unknown as string;
    this.allowHandlingParamsChange = false;
    if (this.allowUrlChange) {
      this.history.replace({
        search: '?' + queryString.stringify(getParams),
      });
    }

    this.handleFetch();
    this.allowHandlingParamsChange = true;
  }

  async setUserId(userId: string) {
    pausePersisting(this);
    this.userId = userId;
    const saveFilters = JSON.parse(
      localStorage.getItem(this.storeName) as string
    );
    await this.setFiltersWithHydrate(
      _.get(saveFilters, 'filters', this.filters)
    );
  }

  setPortal(item?: any) {
    this.portal = item;
  }

  setNoFetch(value: any) {
    this.noFetch = value;
  }

  setOrder(order: any) {
    if (this.order === order) {
      this.direction = this.direction === 'desc' ? 'asc' : 'desc';
    }

    this.order = order;

    const column = this.tableHeader.find((a) => a.name === order);
    if (column && column.customSort) {
      const items = [...this.items];
      items.sort(column.customSort);
      if (this.direction === 'desc') {
        items.reverse();
      }
      this.setItems(items);
      return;
    }

    const getParams = queryString.parse(this.history.location.search);
    getParams.order = JSON.stringify(this.order);
    getParams.direction = JSON.stringify(this.direction);
    this.allowHandlingParamsChange = false;
    if (this.allowUrlChange) {
      this.history.replace({
        search: '?' + queryString.stringify(getParams),
      });
    }

    this.handleFetch();
    this.allowHandlingParamsChange = true;
  }

  setBrandsFilter(brands: any, fieldName: any) {
    const filters = { ...this.filters };
    //brands is null on start for see if is loaded
    if (brands) {
      if (brands.length === 0) {
        delete filters[fieldName];
      } else {
        filters[fieldName] = { inq: brands.map((o: any) => o.value.id) };
      }
      this.setFilters(filters);
    }
  }

  setIsLoading(value: boolean) {
    if (value === true) this.setItems([]);
    this.isLoading = value;
  }

  setIsLoadingCount(value: boolean) {
    this.isLoadingCount = value;
  }

  setItems(items: any[]) {
    this.items = items;
  }

  setCount(count: number) {
    this.count = count;
  }

  deleteFilter(name: string) {
    if (name === 'status' && this.filters.sortBy) {
      const currentSortBy = toJS(this.filters.sortBy);
      if (
        currentSortBy.value &&
        currentSortBy.value.value &&
        currentSortBy.value.value.replace('-', '') !== 'requested' &&
        currentSortBy.value.value.replace('-', '') !== 'updated' &&
        currentSortBy.value.value.replace('-', '') !== 'lastView' &&
        currentSortBy.value.value.replace('-', '') !== 'created'
      ) {
        this.filters = {
          ...this.filters,
          [name]: undefined,
          sortBy: undefined,
        };
      } else {
        this.filters = {
          ...this.filters,
          [name]: undefined,
        };
      }
    } else {
      this.filters = {
        ...this.filters,
        [name]: undefined,
      };
    }
    this.handleFetch();
    this.handleCount();
    /* this.setFilters({
      ...this.filters,
      [name]: null
    }); */
  }

  revertFilter(filter: any) {
    if (filter.name !== 'sortBy') {
      return;
    }
    const currentFilter = toJS(this.filters[filter.name]);
    const newFilter = {
      label: currentFilter.label,
      valueNew: currentFilter.value.revertValue,
      description: currentFilter.value.revertLabel,
      value: {
        label: currentFilter.value.revertLabel,
        value: currentFilter.value.revertValue,
        revertLabel: currentFilter.value.label,
        revertValue: currentFilter.value.value,
      },
      whereClause: {
        ing: [currentFilter.value.revertValue],
      },
    };

    this.filters = {
      ...this.filters,
      [filter.name]: newFilter,
    };
    this.handleFetch();
    this.handleCount();
  }

  get computedFilters() {
    return computedFilters(this.filters);
  }

  get computedFiltersDescription() {
    return computedFiltersDescription(this.filters);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get persistStore() {
    return getPersistedStore(this);
  }

  stopStore() {
    stopPersisting(this);
  }

  async initPersist() {
    await makePersistable(
      this,
      {
        name: this.storeName,
        properties: ['filters'],
        storage: window.localStorage,
        expireIn: 1296000000,
        removeOnExpiration: true,
      },
      { delay: 200, fireImmediately: false }
    );
  }

  async clearStoredDate() {
    await clearPersistedStore(this);
  }

  reinitFilters() {
    const newFilters = { ...this.defaultFilters };
    this.setFilters(newFilters);
    return newFilters;
  }

  handleSetNoLimit() {
    const getParams = queryString.parse(this.history.location.search);
    this.perPage = this.count;
    this.page = 1;
    delete getParams.page;
    delete getParams.perPage;
    this.allowHandlingParamsChange = false;
    if (this.allowUrlChange) {
      this.history.replace({
        search: '?' + queryString.stringify(getParams),
      });
    }

    this.handleFetch();
    this.allowHandlingParamsChange = true;
  }
}
